import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: true,
      title: '分销设备',
      banner: '可批量添加销售的设备(模组)，以进行产品销售。'
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/device/index.vue')
  },
  {
    path: '/list',
    name: 'list',
    meta: {
      layout: true,
      title: '分销明细',
      banner: '可查询分销设备的订单和分润比例，以及查询订单是否已结算。'
    },
    component: () =>
      import(/* webpackChunkName: "list" */ '../views/list/index.vue')
  },
  {
    path: '/count',
    name: 'count',
    meta: {
      layout: true,
      title: '分销统计',
      banner:
        '可以查询累计已结算和未结算的金额，未结算的金额可以下钻查询分润明细。'
    },
    component: () =>
      import(/* webpackChunkName: "count" */ '../views/count/index.vue')
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      layout: true,
      title: '分销账户',
      banner: '可以查询分销商账户信息，包括基础信息，认证资料和结算账户信息。'
    },
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/account/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: false
    },
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/login/index.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
