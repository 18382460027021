import { resolvePost, resolveGet, post } from 'common/request';
import { SUFFIX } from 'common';

// 登陆
export const oauthToken = (params) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/oauth/token',
    params,
    userConfig: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    context: true
  });
};
// 退出登陆
export const oauthRemoveToken = (params) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/oauth/remove/token',
    params,
    userConfig: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  });
};

// 分销设备-批量导入设备
// export const handleUploadDeviceTemplate = (params) => {
//   return resolvePost({
//     suffix: SUFFIX.DSTB,
//     url: '/merchant/device/import',
//     params
//   });
// };

// 分销设备-批量导入模板下载
export const handleDownloadDeviceTemplate = (params) => {
  return post({
    suffix: SUFFIX.DSTB,
    url: '/merchant/device/import/template',
    params,
    userConfig: {
      responseType: 'arraybuffer'
    }
  });
};

// 分销设备-分页查询
export const queryDevicePage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/merchant/device/page',
    params
  });
};

// 分销明细-分页查询
export const queryProfitPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/merchant/profit/detail/page',
    params
  });
};

// 分销统计-查询信息
export const queryProfitTotalPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/merchant/profit/total/info',
    params
  });
};

// 公共上传
export const handleUploadDeviceTemplate = () => {
  return SUFFIX.DSTB + '/merchant/device/import';
};

// 分销账户-查询信息
export const queryDistributorInfo = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/merchant/distributor/info',
    params
  });
};

// 分销设备-设备删除
export const deleteDeviceInfo = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/merchant/device/delete',
    params
  });
};
